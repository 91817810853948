import React from "react"
import Button from "./button"

type Content = {
  header: string
  ctaLink?: string
  ctaText?: string
  onCtaClick?: () => void
}

export default function BlackBar(props: Content) {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/logo-clouds#component-6b864c393af88d7b8a2ac53eaebf6403
  return (
    <div className="bg-gray-700">
      <div className="max-w-7xl mx-auto py-6 px-4 sm:py-6 sm:px-6 lg:px-8">
        <div className="lg:grid lg:grid-cols-1 lg:gap-8 items-center">
          <h2 className="max-w-md mx-auto text-4xl font-extrabold text-white text-center lg:max-w-4xl lg:text-center">
            {props.header}
          </h2>
          {props.ctaText && props.ctaLink && (
            <div className="mx-auto text-center">
              <Button
                text={props.ctaText}
                href={props.ctaLink}
                onClick={props.onCtaClick}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
