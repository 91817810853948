import React from "react"
import { InlineWidget } from "react-calendly"
import { Videos } from "../../data/assets"
import { Routes } from "../../data/routes"
import Card from "../../components/card"
import { ClosingCTA } from "../../components/CTA"
import Img from "../../components/img"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { SecondaryButton } from "../../components/button"
import Video from "../../components/Video"
import BlackBar from "../../components/BlackBar"

const Content = {
  pageName: "May 23 2022 Event Replay",
  metaImage: "/media/march-2022-webinar.png",
  headerImage: "march-2022-webinar.png",
  subheader: "",
  header: `Accelerate student learning. Expand teacher capacity.`,
  description: `Accelerating Student Learning`,
  blackBarHeader: "Accelerating Student Learning",
  video: Videos.webinarOne,
  headerCTALink: Routes.calendly,
  headerCTAText: "Schedule Demo",

  // Demo section
  demoHeader: "Schedule a demo",
  demoDescription: `Use the calendar on the right to pick a time for a Zoom call with one of our Partnership Managers. We can answer your questions,
                    do a demo and put you in touch with other educators using Yup as a “Math Teacher Multiplier” in their school
                    or district.`,
  demoLink:
    "https://calendly.com/christianyup/yup-math-demo?hide_event_type_details=1&hide_gdpr_banner=1",

  // Teaching section
  valuesTop: [
    {
      value:
        "Capture the benefits of High-Dosage Tutoring in and outside of the classroom",
      image: "icon-enrichment.svg",
      imageAltText: "Lightbulb wearing mortarboard",
      description: `Yup's unlimited, on-demand math tutoring supplements classroom-based
        learning throughout the school day and at home. Regardless of the type or
        structure of your school, Yup will work with you to ensure your students and
        staff get the most out of employing effective, evidence-based tutoring practices.`,
      action: "Learn more",
      link: Routes.highDosageTutoring,
    },
    {
      value:
        "Activate the power of putting a Teaching Assistant in every classroom",
      image: "icon-socratic-method.svg",
      imageAltText: "Lightbulb wearing mortarboard",
      description: `Tutoring works best when tutors are connected to the classroom and
        work in collaboration with teachers. Yup provides teachers their own dashboard
        with real-time usage data - including session transcripts - and the ability
        to give instructional guidance to students and tutors. Teachers also receive
        weekly reports with data-driven insights.`,
      action: "Learn more",
      link: Routes.teachers,
    },
  ],

  // Closing CTA
  closingCTA: "Learn what it means to bring Yup to your school or district",
  closingCTAActions: [
    {
      link: Routes.calendly,
      text: "Schedule demo",
    },
  ],
}

function Header() {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/heroes#component-a3c09c7a3b8f4bd90d280722eaff9634
  // Copied from high-dosage-tutoring.tsx
  // Should be split out into its own file and imported
  return (
    <div className="relative bg-background overflow-hidden flex flex-col-reverse lg:block">
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <Img
          className="h-48 w-full sm:h-56 md:h-72 lg:w-full lg:h-full"
          src={Content.headerImage}
          alt=""
          cover={true}
        />
      </div>
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 pb-8 bg-background lg:max-w-2xl lg:w-full">
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-background transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 z-1 pt-12 lg:pb-8">
            <div className="sm:text-center lg:text-left">
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:pr-8">
                {Content.header}
              </h1>
              {Content.description.split(/\n\n/g).map(description => (
                <p key={description} className="mt-3 text-lg lg:pr-24">
                  {description}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function TeachingSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/cta-sections#component-58b6441d042e26470c2d485039ead146
  return (
    <div className="mb-32">
      <Card>
        {Content.valuesTop.map((section, index) => (
          <div
            className={`flex flex-col ${
              index % 2 ? "md:flex-row" : "md:flex-row-reverse"
            } max-w-7xl mx-auto items-center`}
            key={`teaching-section-${index}`}
          >
            <div className="w-full p-4 flex-grow md:w-1/2">
              <Img
                src={section.image}
                alt={section.imageAltText}
                className="w-full h-full max-w-sm object-fit mx-auto"
              />
            </div>
            <div className="text-center md:text-left w-full py-12 md:w-1/2">
              <div className="md:ml-auto md:px-10">
                <p className="mt-2 text-gray-900 text-3xl font-extrabold tracking-tight sm:text-4xl">
                  {section.value}
                </p>
                {section.description.split(/\n\n/g).map((description, i) => (
                  <p
                    className="mt-3 text-lg text-gray-500"
                    key={`teaching-section-${index}-${i}`}
                  >
                    {description}
                  </p>
                ))}
                <div className="mt-8">
                  <SecondaryButton href={section.link} text={section.action} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </Card>
    </div>
  )
}

function DemoSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/cta-sections#component-58b6441d042e26470c2d485039ead146
  return (
    <div className="bg-secondary">
      <div className="max-w-7xl mx-auto flex flex-col md:flex-row">
        <div className="text-left md:text-left w-full py-12 md:w-1/2">
          <div className="md:ml-auto px-6 md:px-10">
            <p className="mt-2 text-white text-3xl font-extrabold sm:text-6xl">
              {Content.demoHeader}
            </p>
            {Content.demoDescription.split(/\n\n/g).map((description, i) => (
              <p
                className="mt-3 text-lg sm:text-3xl text-gray-100"
                key={`demo-section-${i}`}
              >
                {description}
              </p>
            ))}
          </div>
        </div>
        <div className="w-full p-4 flex-grow md:w-1/2" id="calendly">
          <Card>
            <InlineWidget url={Content.demoLink} />
          </Card>
        </div>
      </div>
    </div>
  )
}

function VideoSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/cta-sections#component-58b6441d042e26470c2d485039ead146
  return (
    <Card>
      <div style={{ padding: "28.13% 0 0 0", position: "relative" }}>
        <iframe
          src="https://player.vimeo.com/video/713033777?h=4e2ce50f8d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          title="Accelerating Student Learning"
        ></iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </Card>
  )
}

const Demo = () => {
  return (
    <Layout hidePopup={true} hideDemoLink={true}>
      <Seo
        title={Content.pageName}
        description={Content.description}
        image={Content.metaImage}
        route={Routes.watchWebinarMarch}
      />
      <BlackBar header={Content.blackBarHeader} />
      <VideoSection />
      <DemoSection />
      <TeachingSection />
      <ClosingCTA
        title={Content.closingCTA}
        actions={Content.closingCTAActions}
      />
    </Layout>
  )
}

export default Demo
